.ProseMirror {
  & > p {
    @apply my-4 first:mt-0 last:mb-0;
  }

  *[data-type='horizontalRule'] {
    &.ProseMirror-selectednode,
    &:hover:not(&.ProseMirror-selectednode) {
      @apply bg-gray-50;

      hr {
        @apply border-t-gray-300;
      }
    }

    hr {
      @apply border-0 border-t border-divider bg-divider;
    }
  }
}
