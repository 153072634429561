.ProseMirror {
  /* Placeholder Styles */
  .is-empty::before {
    @apply pointer-events-none float-left h-0 w-full text-gray-400;
  }

  &.ProseMirror-focused {
    /* Slashmenu Placeholder */
    > p.has-focus.is-empty::before {
      content: "Type  '/'  to browse options";
    }

    > [data-type='columns'] > [data-type='column'] > p.is-empty.has-focus::before {
      content: "Type  '/'  to browse options";
    }
  }

  /* Default Placeholder */
  & > .is-editor-empty:first-child::before {
    content: "Tell your story. Type '/' to browse options";
  }

  /* Headings */
  h1 {
    &.is-empty::before,
    &.is-editor-empty::before {
      content: 'Heading 1';
    }
  }
  h2 {
    &.is-empty::before,
    &.is-editor-empty::before {
      content: 'Heading 2';
    }
  }
  h3 {
    &.is-empty::before,
    &.is-editor-empty::before {
      content: 'Heading 3';
    }
  }
  h4 {
    &.is-empty::before,
    &.is-editor-empty::before {
      content: 'Heading 4';
    }
  }
  h5 {
    &.is-empty::before,
    &.is-editor-empty::before {
      content: 'Heading 5';
    }
  }
  h6 {
    &.is-empty::before,
    &.is-editor-empty::before {
      content: 'Heading 6';
    }
  }

  /* List */
  ol,
  ul {
    &.is-editor-empty::before,
    &.is-empty::before {
      content: '';
    }
  }
  li > .is-editor-empty::before,
  li > .is-empty::before {
    content: 'List';
  }

  [data-placeholder][data-type='columns']::before {
    content: none !important;
  }
}
