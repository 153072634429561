.ProseMirror {
  .tableWrapper {
    @apply my-6;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  table {
    @apply box-border w-full border-collapse rounded border-gray-50;

    td,
    th {
      @apply relative min-w-[100px] border border-gray-100 p-2 text-left align-top;

      &:first-of-type:not(a) {
        @apply mt-0;
      }

      p {
        @apply m-0;

        & + p {
          @apply mt-3;
        }
      }
    }

    th {
      @apply font-bold;
    }

    .column-resize-handle {
      @apply pointer-events-none absolute -bottom-[2px] -right-1 top-0 flex w-2;

      &::before {
        @apply ml-2 h-full w-[1px] bg-gray-200;
        content: '';
      }
    }

    .selectedCell {
      @apply border-double border-gray-200 bg-gray-50;
    }

    .grip-column,
    .grip-row {
      @apply absolute z-10 flex cursor-pointer items-center justify-center bg-gray-50;
    }

    .grip-column {
      @apply -top-3 left-0 -ml-[1px] h-3 w-[calc(100%+1px)] border-l border-gray-200;

      &:hover,
      &.selected {
        &::before {
          content: '';
          @apply w-2.5;
        }
      }

      &:hover {
        @apply bg-gray-100;

        &::before {
          @apply border-b-2 border-dotted border-gray-600;
        }
      }

      &.first {
        @apply rounded-tl-sm border-transparent;
      }

      &.last {
        @apply rounded-tr-sm;
      }

      &.selected {
        @apply border-gray-300 bg-gray-300 shadow-sm;

        &::before {
          @apply border-b-2 border-dotted;
        }
      }
    }

    .grip-row {
      @apply -left-3 top-0 -mt-[1px] h-[calc(100%+1px)] w-3 border-t border-gray-200;

      &:hover,
      &.selected {
        &::before {
          @apply h-2.5;
          content: '';
        }
      }
      &:hover {
        @apply bg-gray-100;

        &::before {
          @apply border-l-2 border-dotted border-gray-600;
        }
      }

      &.first {
        @apply rounded-tl-sm border-transparent;
      }

      &.last {
        @apply rounded-bl-sm;
      }

      &.selected {
        @apply border-gray-300 bg-gray-300 shadow-sm;

        &::before {
          @apply border-l-2 border-dotted;
        }
      }
    }
  }
}
