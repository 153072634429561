.ProseMirror {
  /* Columns */
  [data-type='columns'] {
    @apply my-8 grid gap-4;

    &.layout-sidebar-left {
      grid-template-columns: 40fr 60fr;
    }

    &.layout-sidebar-right {
      grid-template-columns: 60fr 40fr;
    }

    &.layout-two-column {
      grid-template-columns: 1fr 1fr;
    }
  }

  [data-type='column'] {
    @apply overflow-auto;
  }

  [data-type='columns'] {
    &.has-focus [data-type='column'],
    &:hover [data-type='column'] {
      @apply border-neutral-300 dark:border-neutral-700;
    }

    [data-type='column'].has-focus {
      @apply border-neutral-400 dark:border-neutral-600;
    }
  }

  [data-type='column'] {
    @apply rounded border-2 border-dotted border-transparent p-1;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-neutral-100 dark:border-neutral-900;
    }
  }

  /* Add ordered list styling */
  ol {
    @apply text-neutral-black;
  }
}
